import React from 'react';
import classNames from 'classnames';

const FooterNav = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-nav',
    className
  );

  return (
    <nav
      {...props}
      className={classes}
    >
      <ul className="list-reset">
        <li>
          <a href="mailto:contact@mostprotocol.org">Contact</a>
        </li>
        <li>
          <a href="https://twitter.com/MostProtocol">Twitter</a>
        </li>
        <li>
          <a href="https://t.me/MostProtocol">Telegram</a>
        </li>
        <li>
          <a href="https://medium.com/@MostProtocol">Medium</a>
        </li>
        <li>
          <a href="https://github.com/MostProtocol">Github</a>
        </li>
      </ul>
    </nav>
  );
}

export default FooterNav;