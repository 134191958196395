import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import { useActiveWeb3React } from '../../hooks'
import { usePair } from '../../data/Reserves'
import { useTotalSupply, useBlockTimestampLast, useMostTokenOracleRate, useMostTokenConsultNow } from '../../data/TotalSupply'
import { getOrchestratorContract } from '../../utils'
import { Token, ChainId, JSBI, Fraction } from '@uniswap/sdk'
import Countdown from 'react-countdown';
import Button from '../elements/Button';
import { calculateGasMargin } from '../../utils'
import { network } from '../../connectors'
import { USDC } from '../../constants'
import { injected } from '../../connectors'
import { useWeb3React as useWeb3ReactCore } from '@web3-react/core'

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const ORCHESTRATOR_ADDRESS = '0x1F79277C5A7b54bD3c730287B756195488fD07DE'
const MOST = new Token(ChainId.MAINNET, '0x784561B89A160990F46DE6dB19571Ca1B5F14bCE', 9, 'MOST', 'MOST')

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Most Protocol Dashboard',
  };

  const { error: networkError, activate, active } = useActiveWeb3React()

  const { chainId, library, account } = useActiveWeb3React()

  const targetTimeDifference = 24 * 60 * 60 * 1000 // 24 hours

  const contractResult = getOrchestratorContract(chainId, library, ORCHESTRATOR_ADDRESS, account)

  const { active: coreActive, error: coreError, activate: coreActivate } = useWeb3ReactCore()

  function onWalletNotFound() {
    const { ethereum } = window

    if (ethereum && ethereum.on && !coreActive && !coreError ) {
      coreActivate(injected, undefined, true).catch(error => {
        console.error('Failed to activate account', error)
      })
    }
  }

  async function onSendTransaction() {
    const estimatedGas = await contractResult.estimateGas.rebase()
    contractResult
      .rebase({
        gasLimit: calculateGasMargin(estimatedGas)
      })
      .then(response => {
        setTransaction(response)
        setRebaseButtonDisabled(true)
        setRebaseButtonText('Rebasing')
        setRebaseButtonColor('dark')
        setTxUrl('https://etherscan.io/tx/' + response.hash)
        setTxHash(response.hash)
      })
      .catch((error) => {
        console.debug('Failed to approve token', error)
        throw error
      })
  }

  const [rebaseButtonDisabled, setRebaseButtonDisabled] = useState(false)
  const [rebaseButtonText, setRebaseButtonText] = useState('Rebase')
  const [rebaseButtonColor, setRebaseButtonColor] = useState('primary')
  const [txUrl, setTxUrl] = useState(null)

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Button tag="a" color={rebaseButtonColor} wideMobile href={txUrl} target='_blank' onClick={rebaseButtonDisabled ? null : account ? onSendTransaction : onWalletNotFound}>{rebaseButtonText}</Button>
    } else {
      // Render a countdown
      return <span>{hours}h:{minutes}m:{seconds}s</span>
    }
  }

  useEffect(() => {
    if (!active && !networkError && !library) {
      activate(network)
    }
  }, [networkError, active, activate, library])

  const [txHash, setTxHash] = useState(null)
  const [, setTransaction] = useState(null)


  if (library && txHash) {
    library.once(txHash, (transaction) => {
      setRebaseButtonDisabled(false)
      setRebaseButtonText('Rebase')
      setRebaseButtonColor('primary')
      setTxUrl(null)
    })
  }

  const blockTimestampLast = useBlockTimestampLast(MOST)
  const oracleRate = useMostTokenOracleRate(MOST, USDC)
  const consultNowResults = useMostTokenConsultNow(ORCHESTRATOR_ADDRESS, USDC)
  const currentOracleRate = consultNowResults[0]
  const upperBound = new Fraction(JSBI.BigInt(106), JSBI.BigInt(100))
  const lowerBound = new Fraction(JSBI.BigInt(96), JSBI.BigInt(100))
  const currentOracleRatePercent = currentOracleRate && new Fraction(currentOracleRate.numerator, currentOracleRate.denominator)

  let rebaseRate
  if (currentOracleRatePercent) {
    if (currentOracleRatePercent.greaterThan(upperBound)) {
      rebaseRate = new Fraction(JSBI.BigInt(1), JSBI.BigInt(1)).subtract(currentOracleRatePercent).divide(currentOracleRatePercent).divide(JSBI.BigInt(10)).multiply(JSBI.BigInt(100))
    } else if (currentOracleRatePercent.lessThan(lowerBound)) {
      rebaseRate = new Fraction(JSBI.BigInt(1), JSBI.BigInt(1)).subtract(currentOracleRatePercent).divide(JSBI.BigInt(10)).multiply(JSBI.BigInt(100))
    } else {
      rebaseRate = new Fraction(JSBI.BigInt(0), JSBI.BigInt(1))
    }
  }

  const pair = usePair(MOST, USDC)
  const totalSupply = useTotalSupply(MOST)

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />

          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0 text-color-high">
                    &emsp;&emsp;
                    {blockTimestampLast ?
                    <Countdown
                      date={ blockTimestampLast * 1000 + targetTimeDifference }
                      renderer={renderer}
                    /> : 'Loading...'}
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-link">
                    NEXT REBASE
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0 text-color-high">
                    &emsp;&emsp;
                    ${ oracleRate && oracleRate.toFixed(2) } / ${ currentOracleRate && currentOracleRate.toFixed(2)} ({rebaseRate && rebaseRate.toFixed(2)}%)
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">LAST</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    NEXT ORACLE RATE
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0 text-color-high">
                    &emsp;&emsp;$1.00
                  </p>  
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-link">
                    PRICE TARGET
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0 text-color-high">
                    &emsp;&emsp;
                    { totalSupply && totalSupply.toFixed(4) } 𝕄
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-link">
                    TOTAL SUPPLY
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0 text-color-high">
                    &emsp;&emsp;
                    ${ pair[1] && pair[1].priceOf(MOST).toFixed(2) }
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-link">
                    MARKET PRICE
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0 text-color-high">
                    &emsp;&emsp;
                    ${ pair[1] && pair[1].priceOf(MOST).quote(totalSupply).toFixed(2) }
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-link">
                    MARKET CAP
                  </span>
                </div>
              </div>
            </div>

          </div>
          <div className={tilesClasses}></div>
        </div>
      </div>
    </section>
  );
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;