import { BigNumber } from '@ethersproject/bignumber'
import { Token, TokenAmount } from '@uniswap/sdk'
import { useTokenContract, useMostTokenContract, useOrchestratorContract, useMostOracleContract } from '../hooks/useContract'
import { useSingleCallResult, Result } from '../state/multicall/hooks'

// returns undefined if input token is undefined, or fails to get token contract,
// or contract total supply cannot be fetched
export function useTotalSupply(token?: Token): TokenAmount | undefined {
  const contract = useTokenContract(token?.address, false)

  const totalSupply: BigNumber = useSingleCallResult(contract, 'totalSupply')?.result?.[0]

  return token && totalSupply ? new TokenAmount(token, totalSupply.toString()) : undefined
}

export function useMostTokenOracleRate(token?: Token, tokenB?: Token): TokenAmount | undefined {
  const contract = useMostTokenContract(token?.address, false)

  const oracleRate: BigNumber = useSingleCallResult(contract, 'consult', [token?.address, 1000000000])?.result?.[0]

  return token && tokenB && oracleRate ? new TokenAmount(tokenB, oracleRate.toString()) : undefined
}

export function useMbtcMostOracleRate(contractAddress?: string, token?: Token, tokenB?: Token): TokenAmount | undefined {
  const contract = useMostOracleContract(contractAddress, false)

  const oracleRate: BigNumber = useSingleCallResult(contract, 'consult', [token?.address, 1000000000])?.result?.[0]

  return token && tokenB && oracleRate ? new TokenAmount(tokenB, oracleRate.toString()) : undefined
}

export function useMostTokenConsultNow(contractAddress?: string, tokenB?: Token): [TokenAmount | undefined, BigNumber | undefined, BigNumber | undefined] {
  const contract = useOrchestratorContract(contractAddress, false)

  const consultNowResults: Result | undefined = useSingleCallResult(contract, 'consultNow', [1000000000])?.result

  return [tokenB && consultNowResults ? new TokenAmount(tokenB, consultNowResults[0].toString()) : undefined, consultNowResults ? consultNowResults[1].toString() : undefined, consultNowResults ? consultNowResults[2].toString() : undefined]
}

export function useMbtcOracleConsultNow(contractAddress?: string, tokenB?: Token): [TokenAmount | undefined, BigNumber | undefined, BigNumber | undefined] {
  const contract = useMostOracleContract(contractAddress, false)

  const consultNowResults: Result | undefined = useSingleCallResult(contract, 'consultNow', [1000000000])?.result

  return [tokenB && consultNowResults ? new TokenAmount(tokenB, consultNowResults[0].toString()) : undefined, consultNowResults ? consultNowResults[1].toString() : undefined, consultNowResults ? consultNowResults[2].toString() : undefined]
}

export function useBlockTimestampLast(token?: Token): BigNumber | undefined {
  const contract = useMostTokenContract(token?.address, false)

  const blockTimestampLast: BigNumber = useSingleCallResult(contract, 'blockTimestampLast')?.result?.[0]

  return blockTimestampLast
}

export function useMbtcBlockTimestampLast(contractAddress?: string): BigNumber | undefined {
  const contract = useMostOracleContract(contractAddress, false)

  const blockTimestampLast: BigNumber = useSingleCallResult(contract, 'blockTimestampLast')?.result?.[0]

  return blockTimestampLast
}
