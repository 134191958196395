import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import Testimonial from '../components/sections/Testimonial';
import Web3ReactManager from '../components/Web3ReactManager';

const Home = () => {
  return (
    <>
      <Web3ReactManager />
      <Hero className="illustration-section-01" />
      <FeaturesTiles />
      <Testimonial topDivider />
    </>
  );
}

export default Home;